/* eslint-disable react/jsx-max-props-per-line */
import React, {memo, useCallback, useEffect, useState} from "react";
import Link from "components/common/Link";
import Image from "next/image";
import { CountryPreference, ReduxState, SidebarNavProp } from 'types';
import navData from "./navData";
import { useRouter } from "next/router";
import greenBubbleIcon from "assets/green-bubble.svg";
import dynamic from "next/dynamic";
import { COUNTRY_CODE } from "constants/store";
import TooltipForNoAccess from "../../TooltipForNoAccess";
import isAuthorized from "../../../utils/isAuthorized";
import { useSelector } from 'react-redux';
import rnaAnalytics from "services/analytics/rnaAnalytics";
import { AnalyticsEvent, AnalyticsEventAction, AnalyticsPageSectionName } from "constants/analytics";
import { isInvestorStore } from "utils/auth";
import { checkInvestorStoreNavAccess } from "utils/misc";
import chevronRight from "assets/chevron-right-dark.svg";
import productTesting from "assets/product-testing.svg";

const SideNavTooltip = dynamic(
  () => import("components/SidebarNav/SideNavTooltip")
);
const NavGroup = dynamic(
  () => import("components/SidebarNav/_default/NavGroup")
);

interface Props {
  togglePopup: (popupId: string) => void;
  showFullWidthNavbar: boolean;
  hasNewCloutPosts?: boolean;
  userCountryPreference: CountryPreference;
}

const commonNavClass =
  "flex items-center pl-[16px] pr-[10px] py-[10px] mb-[10px] last:mb-0 hover:bg-theme_1_white hover:font-bold hover:mr-[3px] border-l-transparent";
const disabledNavClass = "pointer-events-none cursor-not-allowed opacity-50  ";

const SidebarNav: React.FC<Props> = ({
  togglePopup,
  showFullWidthNavbar = true,
  hasNewCloutPosts = false,
  userCountryPreference,
}) => {
  const user = useSelector((state: ReduxState) => state?.user);
  const isB2BStore = user?.shopifyStoreData?.isB2BStore;

  const { whatsappSubscriptionDetails, shopifyStoreData } = user;
  const router = useRouter();
  const activeStyle =
    "bg-theme_1_black text-white font-bold hover:!bg-theme_1_black hover:!mr-0";
  const [tooltipLabel, setTooltipLabel] = useState("");

  const handleMouseInOut = useCallback((e, label) => {
    const menuLabel = e.target.dataset.menu;

    if (e.type === "mouseenter" && menuLabel === label) {
      setTooltipLabel(menuLabel);
    } else {
      setTooltipLabel("");
    }
  }, []);

  const sidebarItems = React.useMemo(() => {
    const newNavData =JSON.parse(JSON.stringify(navData));
    if (whatsappSubscriptionDetails?.isWhatsappDashboardEnabled === undefined || !whatsappSubscriptionDetails?.isWhatsappDashboardEnabled) {
      const vasIndex = newNavData[1].findIndex((section: any) => section.id === 'vas');
      if (vasIndex !== -1) {
        newNavData[1].splice(vasIndex, 1);
      }
    }
    return newNavData;
  }, [whatsappSubscriptionDetails?.isWhatsappDashboardEnabled]);

  const handleNavItemClick = async ({ heading, label, index, position }: { heading?: string; label: string; index?: number; position?: string }) => {
    rnaAnalytics.clickEvent({
      action: AnalyticsEventAction.SideNavbarItemClicked,
      eventData: {
        [AnalyticsEvent.Extras]: {
          [AnalyticsEvent.PageSectionName]: AnalyticsPageSectionName.SideNavbar,
          [AnalyticsEvent.ItemName]: label,
          [AnalyticsEvent.Position]: position || (index && index+1),
          ...(heading && { [AnalyticsEvent.ItemHeading]: heading }),
        }
      }
    }).send();
  }

  const getNavItemPosition = (id: string) => {
    return sidebarItems?.flat()?.findIndex((nav: any) => nav.id === id);
  }

  const [testingTagFlippingBoolean, setTestingTagFlippingBoolean] = useState(true);

  useEffect(() => {
    // Toggle between the two texts every 5 seconds (5000ms)
    const interval = setInterval(() => {
      setTestingTagFlippingBoolean((prev) => !prev);
    }, 7000);

    // Cleanup the interval on unmount
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="flex flex-col py-[14px]">
        {shopifyStoreData?.collectionIdForHeroProductTesting
          ? (<div
            className="px-2"
            onClick={() => { handleNavItemClick({ label: 'product_testing_panel', position: '-1' }) }}
          >
            <Link
              passHref
              legacyBehavior={false}
              target="_blank"
              href={`/collection/${shopifyStoreData?.collectionIdForHeroProductTesting}`}
              prefetch={false}>
              <div className="relative bg-[#ffca00]/20 rounded-lg flex items-center gap-1 py-2.5 px-2 pr-2 cursor-pointer">
                <div className={"w-[24px] min-w-[24px] h-[24px] min-h-[24px]"}>
                  <Image
                    src={productTesting}
                    height={22}
                    width={25.2}
                    alt='productTesting'
                  />
                </div>
                {showFullWidthNavbar ? <>
                  <div className="flex-1 whitespace-nowrap">
                    <div className="relative w-[147px] h-[24px] my-auto overflow-hidden">
                      <div
                        className={`absolute w-full h-full transform transition-transform duration-1000 ease-in-out ${
                          testingTagFlippingBoolean ? "translate-y-0" : "-translate-y-full"
                        }`}
                      >
                        <div className="self-stretch text-neutral-950 text-[9px] font-normal capitalize leading-[10px]">Introducing</div>
                        <div className="text-neutral-950 text-xs font-bold leading-[14px]">Products For Testing</div>
                      </div>

                      <div
                        className={`absolute w-full h-full flex items-center justify-start transform transition-transform duration-1000 ease-in-out ${
                          testingTagFlippingBoolean ? "translate-y-full" : "translate-y-0"
                        }`}
                      >
                        <p className="text-neutral-950 text-xs font-bold leading-[14px]">Get Exciting Benefits</p>
                      </div>
                    </div>
                  </div>
                  <Image
                    src={chevronRight}
                    height={14}
                    width={14}
                    alt='chevronRight'
                  />
                </> : null}
              </div>
            </Link>
          </div>)
        : ''}
        {sidebarItems?.map((navSections: SidebarNavProp[], navSectionIndex: number) => {
          const isNavItem = navSections.some((navSection) => navSection.countryAccess.includes(userCountryPreference?.code || COUNTRY_CODE.india));
          const isAnalyticsNav = navSections.some((navSection) => checkInvestorStoreNavAccess(navSection.navId));

          if (!isNavItem || (isInvestorStore() && !isAnalyticsNav)) return "";

          return (
            // menu sections
            <div
              className={"flex flex-col py-[10px] border-b border-theme_1_linebreak last:border-none text-xs text-theme_1_black font-normal"}
              key={navSectionIndex}
            >
              {/* menu items */}
              {navSections.map((nav, navItemIndex) => {
                if (isInvestorStore() && !checkInvestorStoreNavAccess(nav.navId)) return;
                if (!nav.countryAccess.includes(userCountryPreference?.code || COUNTRY_CODE.india) && !nav.countryAccess.includes(COUNTRY_CODE.all)) return;

                if (nav.isPopup) {
                  return (
                    <button
                      key={nav.id}
                      id={nav.navId}
                      className={`${commonNavClass}`}
                      onClick={() => {
                        handleNavItemClick({ label: nav.label, index: getNavItemPosition(nav.id) });
                        togglePopup(nav.id)
                      }}
                    >
                      <div className="w-[24px] min-w-[24px] h-[24px] min-h-[24px]">
                        <Image
                          width={24}
                          height={24}
                          src={nav.icon || "/"}
                          alt={nav.navId}
                          loading="eager"
                        />
                      </div>
                      { showFullWidthNavbar ? <span className="ml-[10px] whitespace-nowrap">{nav.label}</span> : null }
                    </button>
                  );
                }

                /** is a Nav Group with child navs - Accordian with navs */
                if ((nav.childNavs || [])?.length > 0) {
                  return (
                    <NavGroup
                      key={`navgroup-${navItemIndex}`}
                      showFullWidthNavbar={showFullWidthNavbar}
                      navGroup={nav}
                      onClick={(navData: any) => handleNavItemClick({ heading: navData.heading, label: navData.label, index: getNavItemPosition(nav.id) })}
                    />
                  );
                }

                const isActive = (!nav.isHome && router.pathname.startsWith(nav.id)) ||  (nav.isHome && router.pathname === "/");

                return (
                  <TooltipForNoAccess
                    key={nav.label}
                    message={`You don't have access to ${nav.label}`}
                    hasAccess={isAuthorized({ permissionToCheck: nav.permission })}
                    position="right"
                  >
                    <Link href={nav.link || "/"} passHref prefetch={false}>
                      <a
                        title={
                          isAuthorized({ permissionToCheck: nav.permission })
                            ? nav.label
                            : `You do not have access to ${nav.label}`
                        }
                        id={nav.navId.replace(/\//g, "")}
                        className={`${
                          !isAuthorized({
                            permissionToCheck: nav.permission,
                          }) && disabledNavClass
                        } ${commonNavClass}  ${
                          isActive ? activeStyle : ""
                        }`}
                        data-menu={nav.label}
                        {...(nav.tooltipText && {
                          onMouseEnter: (e) => handleMouseInOut(e, nav.label),
                          onMouseLeave: (e) => handleMouseInOut(e, nav.label),
                        })}
                        onClick={() => handleNavItemClick({ label: nav.label, index: getNavItemPosition(nav.id) })}
                      >
                        <div className="w-[24px] min-w-[24px] h-[24px] min-h-[24px]">
                          <Image
                            width={24}
                            height={24}
                            src={(isActive ? nav.iconLight : nav.icon) || "/"}
                            alt={nav.navId}
                            loading="eager"
                            quality={100}
                          />
                        </div>
                        {showFullWidthNavbar ? (
                          <p className="ml-[10px] flex items-center gap-[20px]">
                            <span className="whitespace-nowrap">
                              {nav.label}
                            </span>
                            {nav.id === "/clout-updates" && hasNewCloutPosts ? (
                              <Image
                                src={greenBubbleIcon}
                                alt="update-bubble-icon"
                              />
                            ) : null}
                          </p>
                        ) : null}
                        {tooltipLabel == nav.label && (
                          <SideNavTooltip
                            message={nav.tooltipText || ""}
                            position={navItemIndex}
                          />
                        )}
                      </a>
                    </Link>
                  </TooltipForNoAccess>
                );
              })}
            </div>
          );
        })}
      </div>
      {showFullWidthNavbar && (
        <div className="sticky bottom-0 left-0 bg-[#F8F8F8] px-[10px] py-[14px] w-[98%] text-center text-sm">
          <small className="text-[#757575] whitespace-nowrap">
            Clout
            <a 
              onClick={() => handleNavItemClick({ label: 'T&C', position: 'footer' })}
              href={isB2BStore ? "/tnc/b2b" : "/tnc"} rel="noreferrer" target={"_blank"}>
              {" "}
              <b className="underline">{"T&C"}</b>{" "}
            </a>
            {isB2BStore ? (
              <span>
                {", "}
                <a 
                  onClick={() => handleNavItemClick({ label: 'Buyers Agreement', position: 'footer' })}
                  href="/tnc/b2b/buyers-agreement" rel="noreferrer" target={"_blank"}>
                  <b className="underline">{"Buyers Agreement"}</b>
                </a>
                <br />{" & "}
              </span>
            ) : 'and'}
            <a 
              onClick={() => handleNavItemClick({ label: 'Privacy Policy', position: 'footer' })}
              href={isB2BStore ? "/privacy/b2b" : "/privacy"} rel="noreferrer" target={"_blank"}>
              {" "}
              <b className="underline">Privacy Policy</b>
            </a>
          </small>
        </div>
      )}
    </>
  );
};

SidebarNav.defaultProps = {
  showFullWidthNavbar: true,
};

const areEqual = (prevProps: Props, nextProps: Props) => {
  return (
    prevProps.showFullWidthNavbar === nextProps.showFullWidthNavbar &&
    prevProps.hasNewCloutPosts === nextProps.hasNewCloutPosts &&
    prevProps.userCountryPreference?.id === nextProps.userCountryPreference?.id
  );
};

export default memo(SidebarNav, areEqual);
